import NavDropdownMenu, {
  DropdownItem,
} from "../../../core/components/NavDropdownMenu";

const dropdownItems: DropdownItem[] = [
  {
    id: "testing_services",
  },
  {
    id: "security_testing_services_overview",
    href: "https://www.blackduck.com/services/security-testing.html",
  },
  {
    id: "penetration_testing",
    href: "https://www.blackduck.com/services/penetration-testing.html",
  },
  {
    id: "mast",
    href: "https://www.blackduck.com/services/security-testing/mobile-application-security-testing.html",
  },
  {},
  {
    id: "program_services",
  },
  {
    id: "threat_risk_assessments",
    href: "https://www.blackduck.com/services/security-program/software-architecture-design.html",
  },
  {
    id: "program_strategy_planning",
    href: "https://www.blackduck.com/services/security-program/strategy-planning.html",
  },
  {
    id: "security_training",
    href: "https://www.blackduck.com/training.html",
  },
  {
    id: "implementation_deployment",
    href: "https://www.blackduck.com/customer-success/implementation.html",
  },
  {},
  {
    id: "audit_services",
  },
  {
    id: "open_source_security_audits",
    href: "https://www.blackduck.com/services/open-source-software-audit.html",
  },
];

export default function Services() {
  return (
    <NavDropdownMenu
      ns="home-menu"
      id="services"
      dropdownItems={dropdownItems}
    ></NavDropdownMenu>
  );
}
