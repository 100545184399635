import "react-flagpack/dist/style.css";
import { useTranslation } from "react-i18next";
import CountryFlag from "./CountryFlag";

export default function ContentFooter() {
  const { t } = useTranslation(["home-content"]);

  return (
    <>
      <div className="text-sm-center">
        <p className="mt-4">{t("synopsys")}</p>
      </div>
      <div className="text-sm-center">
        <div className="mb-1">
          {t("reseller")} <CountryFlag code="BO"></CountryFlag>{" "}
          <CountryFlag code="CL"></CountryFlag>{" "}
          <CountryFlag code="CO"></CountryFlag>{" "}
          <CountryFlag code="HN"></CountryFlag>{" "}
          <CountryFlag code="MX"></CountryFlag>{" "}
          <CountryFlag code="PA"></CountryFlag>{" "}
          <CountryFlag code="PE"></CountryFlag>{" "}
          <CountryFlag code="PR"></CountryFlag>
        </div>
        <p className="mb-1">
          {t("awards.gartner_ast.leader")}{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.blackduck.com/blog/gartner-mq-ast.html"
          >
            {t("awards.gartner_ast.gartner")}
          </a>{" "}
          {t("awards.gartner_ast.for")}
        </p>

        <p className="mb-1">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.blackduck.com/blog/black-duck-named-leader-forrester-wave-sast.html"
          >
            {t("awards.forrester_sast.year")}
          </a>{" "}
          {t("awards.forrester_sast.recognizes")}
        </p>

        <p className="mb-1">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.blackduck.com/blog/forrester-wave-sca.html"
          >
            {t("awards.forrester_sca.year")}
          </a>{" "}
          {t("awards.forrester_sca.recognizes")}
        </p>
      </div>
    </>
  );
}
