import { Container } from "react-bootstrap";
import "./Content.css";
import ContentCarousel from "./ContentCarousel";
import ContentFooter from "./ContentFooter";
import ContentHeader from "./ContentHeader";

export default function Content() {
  return (
    <Container className="stgt-height100">
      <ContentHeader></ContentHeader>
      <ContentCarousel></ContentCarousel>
      <ContentFooter></ContentFooter>
    </Container>
  );
}
