import {
  Button,
  Carousel,
  CarouselItem,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BlackDuckLogo from "../../../../assets/blackduck/BlackDuckLogo-White.svg";
import GartnerAST from "../../../../assets/blackduck/Gartner_Magic_Quadrant_AST_2023-final-bg.svg";
import "./ContentCarousel.css";

function SlidePlaceHolder() {
  return (
    <svg
      className="stgt-placeholder-img stgt-placeholder-img-lg"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      preserveAspectRatio="xMidYMid slice"
      focusable="false"
    >
      <rect width="100%" height="100%" fill="var(--bs-tertiary-color)" />
    </svg>
  );
}

export default function ContentCarousel() {
  const { t } = useTranslation(["home-content"]);

  return (
    <Carousel slide className="mb-6">
      <CarouselItem>
        <SlidePlaceHolder></SlidePlaceHolder>
        <Container>
          <Carousel.Caption className="text-start">
            <img
              src={BlackDuckLogo}
              className="stgt-blackduck-logo-slide stgt-blackduck-logo-slide-lg mb-4 mx-auto d-block"
              alt="Black Duck"
            />
            <h1>{t("carousel.new_name.0")}</h1>
            <h4>{t("carousel.new_name.1")}</h4>
            <p>
              <Button
                className="btn-lg btn-dark"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.youtube.com/watch?v=nbOjWPFDE2s&feature=youtu.be"
              >
                {t("carousel.new_name.button")}
              </Button>
            </p>
          </Carousel.Caption>
        </Container>
      </CarouselItem>

      <CarouselItem>
        <SlidePlaceHolder></SlidePlaceHolder>
        <Container>
          <Carousel.Caption className="text-start">
            <h1>{t("carousel.oss_report.0")}</h1>
            <h4>{t("carousel.oss_report.1")}</h4>
            <p>
              <Button
                className="btn-lg btn-dark"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.blackduck.com/resources/analyst-reports/open-source-security-risk-analysis.html"
              >
                {t("carousel.oss_report.button")}
              </Button>
            </p>
          </Carousel.Caption>
        </Container>
      </CarouselItem>

      <CarouselItem>
        <SlidePlaceHolder></SlidePlaceHolder>
        <Container>
          <Carousel.Caption className="text-start">
            <h1>{t("carousel.polaris_platform.0")}</h1>
            <h4>{t("carousel.polaris_platform.1")}</h4>
            <p>
              <Button
                className="btn-lg btn-dark"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.blackduck.com/platform.html"
              >
                {t("carousel.polaris_platform.button")}
              </Button>
            </p>
          </Carousel.Caption>
        </Container>
      </CarouselItem>

      <CarouselItem>
        <SlidePlaceHolder></SlidePlaceHolder>
        <Container>
          <Carousel.Caption className="text-start">
            <Row>
              <Col xxl="8">
                <h1>{t("carousel.gartner_report.0")}</h1>
                <h4>{t("carousel.gartner_report.1")}</h4>
                <p>
                  <Button
                    className="btn-lg btn-dark"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.blackduck.com/platform.html"
                  >
                    {t("carousel.gartner_report.button")}
                  </Button>
                </p>
              </Col>
              <Col xxl="4" className="d-none d-xxl-block">
                <img src={GartnerAST} alt="Gartner AST"></img>
              </Col>
            </Row>
          </Carousel.Caption>
        </Container>
      </CarouselItem>
    </Carousel>
  );
}
