import NavDropdownMenu, {
  DropdownItem,
} from "../../../core/components/NavDropdownMenu";

const dropdownItems: DropdownItem[] = [
  {
    id: "latest_updates",
  },
  {
    id: "newsroom",
    href: "https://news.blackduck.com/",
  },
  {
    id: "blog",
    href: "https://www.blackduck.com/blog.html",
  },
  {
    id: "cyrc",
    href: "https://www.blackduck.com/resources/cybersecurity-research-center.html",
  },
  {},
  {
    id: "customer_resources",
  },
  {
    id: "documentation",
    href: "https://documentation.blackduck.com",
  },
  {},
  {
    id: "other_resources",
  },
  {
    id: "ebooks",
    href: "https://www.blackduck.com/resources/ebooks.html",
  },
  {
    id: "case_studies",
    href: "https://www.blackduck.com/resources/case-studies.html",
  },
  {
    id: "research_reports",
    href: "https://www.blackduck.com/resources/analyst-reports.html",
  },
  {
    id: "app_sec_glossary",
    href: "https://www.blackduck.com/glossary.html",
  },
  {
    id: "resource_library",
    href: "https://www.blackduck.com/resources.html",
  },
  {},
  {
    id: "legal",
  },
  {
    id: "security_commitments",
    href: "https://www.blackduck.com/company/legal/security-commitments.html",
  },
];

export default function Resources() {
  return (
    <NavDropdownMenu
      ns="home-menu"
      id="resources"
      dropdownItems={dropdownItems}
    ></NavDropdownMenu>
  );
}
