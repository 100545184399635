import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import BlackDuck from "./BlackDuck";
import Language from "./Language";
import LinkedIn from "./LinkedIn";
import "./Menu.css";
import Products from "./Products";
import Resources from "./Resources";
import Services from "./Services";
import Solutions from "./Solutions";

export default function Menu() {
  return (
    <Navbar expand="lg" className="bg-primary" fixed="top">
      <Container fluid>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          data-bs-toggle="collapse"
        />
        <Navbar.Brand className="mb-0 h1" href="https://softegrity.cl/">
          Softegrity
        </Navbar.Brand>
        <Navbar.Collapse>
          <Nav className="me-auto mb-2 mb-lg-0">
            <Solutions></Solutions>
            <Products></Products>
            <Services></Services>
            <Resources></Resources>
          </Nav>
          <Nav>
            <BlackDuck></BlackDuck>
            <LinkedIn></LinkedIn>
            <Language></Language>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
