import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function ContentHeader() {
  const { t } = useTranslation(["home-content"]);

  return (
    <div className="text-sm-center">
      <br />
      <Row>
        <Col md="12" lg="12">
          <h3>{t("header.0")}</h3>
          <h5>{t("header.1")}</h5>
          <p>{t("header.2")}</p>
        </Col>
      </Row>
    </div>
  );
}
