import "bootstrap/dist/css/bootstrap.min.css";
import Content from "./components/content/Content";
import Footer from "./components/footer/Footer";
import Menu from "./components/menu/Menu";
import "./Homepage.css";

export default function Homepage() {
  return (
    <>
      <Menu></Menu>
      <Content></Content>
      <Footer></Footer>
    </>
  );
}
