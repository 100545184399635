import { NavItem, NavLink } from "react-bootstrap";
import BlackDuckLogo from "../../../../assets/blackduck/BlackDuckLogo-White.svg";
import "./BlackDuck.css";

export default function BlackDuck() {
  return (
    <NavItem>
      <NavLink
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.blackduck.com"
      >
        <img
          src={BlackDuckLogo}
          className="blackduck-logo-navbar"
          alt="Black Duck"
        ></img>{" "}
        Reseller Partner
      </NavLink>
    </NavItem>
  );
}
