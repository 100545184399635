import NavDropdownMenu, {
  DropdownItem,
} from "../../../core/components/NavDropdownMenu";

const dropdownItems: DropdownItem[] = [
  {
    id: "bytech",
  },
  {
    id: "sast",
    href: "https://www.blackduck.com/static-analysis-tools-sast.html",
  },
  {
    id: "sca",
    href: "https://www.blackduck.com/software-composition-analysis-tools.html",
  },
  {
    id: "dast",
    href: "https://www.blackduck.com/dast.html",
  },
  {
    id: "iast",
    href: "https://www.blackduck.com/interactive-application-security-testing.html",
  },
  {
    id: "penetration_testing",
    href: "https://www.blackduck.com/services/penetration-testing.html",
  },
  {
    id: "mast",
    href: "https://www.blackduck.com/services/security-testing/mobile-application-security-testing.html",
  },
  {
    id: "aspm",
    href: "https://www.blackduck.com/software-risk-manager.html",
  },
  {
    id: "fuzz",
    href: "https://www.blackduck.com/fuzz-testing.html",
  },
  {},
  {
    id: "byindustry",
  },
  {
    id: "automotive",
    href: "https://www.blackduck.com/solutions/automotive.html",
  },
  {
    id: "financial_services",
    href: "https://www.blackduck.com/solutions/financial-services.html",
  },
  {
    id: "iot_embedded",
    href: "https://www.blackduck.com/solutions/iot-embedded.html",
  },
  {
    id: "healthcare",
    href: "https://www.blackduck.com/solutions/healthcare.html",
  },
  {
    id: "government",
    href: "https://www.blackduck.com/solutions/government.html",
  },
  {},
  {
    id: "byrole",
  },
  {
    id: "dev_devops",
    href: "https://www.blackduck.com/solutions/dev-devops.html",
  },
  {
    id: "security_teams",
    href: "https://www.blackduck.com/solutions/security-teams.html",
  },
  {
    id: "legal_teams",
    href: "https://www.blackduck.com/solutions/legal-teams.html",
  },
];

export default function Solutions() {
  return (
    <NavDropdownMenu
      ns="home-menu"
      id="solutions"
      dropdownItems={dropdownItems}
    ></NavDropdownMenu>
  );
}
