import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import i18nextHttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const currentHost = process.env.PUBLIC_URL || "";
const lookupFromPathIndex = currentHost.match(/[/]/g)?.length || 0;

i18next
  .use(i18nextHttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "es"],
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${currentHost}/assets/locales/{{ns}}/{{lng}}.json`,
    },
    detection: {
      // order and from where user language should be detected
      order: ["localStorage", "navigator"],
      // keys or params to lookup language from
      lookupQuerystring: "lng",
      lookupLocalStorage: "lng",
      lookupFromPathIndex: Number(lookupFromPathIndex),
      // cache user language on
      caches: ["localStorage"],
      // languages to not persist (cookie, localStorage)
      excludeCacheFor: ["cimode"],
    },
  });

export default i18next;
