import { NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import NavDropdownExternal from "./NavDropdownItemExternal";

export interface DropdownItem {
  id?: string;
  href?: string;
}

interface NavDropdownMenuProps {
  ns: string;
  id: string;
  dropdownItems: DropdownItem[];
}

let tranlate: any;

function renderDropdownDivider(idParent: string, index: number) {
  const key = `${idParent}_${index}`;
  return <NavDropdown.Divider key={key} />;
}

function renderDropdownExternal(idParent: string, id: string, href: string) {
  const key = `${idParent}_${id}`;
  return (
    <NavDropdownExternal key={key} href={href}>
      {tranlate(id)}
    </NavDropdownExternal>
  );
}

function renderDropdownText(idParent: string, id: string) {
  const key = `${idParent}_${id}`;
  return (
    <NavDropdown.Item key={key} disabled>
      {tranlate(id)}
    </NavDropdown.Item>
  );
}

function renderDropdownItem(
  idParent: string,
  dropdownItem: DropdownItem,
  index: number
) {
  let result;
  if (dropdownItem.id && dropdownItem.href) {
    result = renderDropdownExternal(
      idParent,
      dropdownItem.id,
      dropdownItem.href
    );
  } else if (dropdownItem.id) {
    result = renderDropdownText(idParent, dropdownItem.id);
  } else {
    result = renderDropdownDivider(idParent, index);
  }

  return result;
}

function renderDropdownItems(props: NavDropdownMenuProps) {
  const idParent = props.id;
  return props.dropdownItems.map((dropdownItem, index) =>
    renderDropdownItem(idParent, dropdownItem, index)
  );
}

export default function NavDropdownMenu(props: NavDropdownMenuProps) {
  const { t } = useTranslation([props.ns]);
  tranlate = t;
  return (
    <NavDropdown title={t(props.id)}>{renderDropdownItems(props)}</NavDropdown>
  );
}
