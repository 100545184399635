import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavItem, NavLink } from "react-bootstrap";
import { useTranslation } from "react-i18next";

let language: string;

export default function Language() {
  const { i18n } = useTranslation();
  language = i18n.language === "es" ? "en" : "es";

  return (
    <NavItem>
      <NavLink onClick={() => i18n.changeLanguage(language)}>
        <FontAwesomeIcon icon={faGlobe}></FontAwesomeIcon>{" "}
        {language.toUpperCase()}
      </NavLink>
    </NavItem>
  );
}
