import NavDropdownMenu, {
  DropdownItem,
} from "../../../core/components/NavDropdownMenu";

const dropdownItems: DropdownItem[] = [
  {
    id: "integrated_sas_s_platform",
  },
  {
    id: "polaris_platform",
    href: "https://www.blackduck.com/platform.html",
  },
  {
    id: "fast_static",
    href: "https://www.blackduck.com/platform.html#A",
  },
  {
    id: "fast_sca",
    href: "https://www.blackduck.com/platform.html#A",
  },
  {
    id: "fast_dynamic",
    href: "https://www.blackduck.com/platform.html#A",
  },
  {},
  { id: "tools" },
  {
    id: "coverity_static",
    href: "https://www.blackduck.com/static-analysis-tools-sast/coverity.html",
  },
  {
    id: "blackduck_sca",
    href: "https://www.blackduck.com/software-composition-analysis-tools/black-duck-sca.html",
  },
  {
    id: "continuous_dynamic",
    href: "https://www.blackduck.com/dast.html",
  },
  {
    id: "seeker_interactive",
    href: "https://www.blackduck.com/interactive-application-security-testing.html",
  },
  {
    id: "srm_aspm",
    href: "https://www.blackduck.com/software-risk-manager.html",
  },
  {
    id: "fuzz",
    href: "https://www.blackduck.com/fuzz-testing.html",
  },
  {},
  {
    id: "integrations",
  },
  {
    id: "code_sight_ide",
    href: "https://www.blackduck.com/code-sight.html",
  },
  {
    id: "scm_integrations",
    href: "https://www.blackduck.com/integrations.html#scm",
  },
  {
    id: "build_ci_integrations",
    href: "https://www.blackduck.com/integrations.html#build-ci",
  },
  {
    id: "workflow_integrations",
    href: "https://www.blackduck.com/integrations.html#workflow",
  },
  {
    id: "security_integrations",
    href: "https://www.blackduck.com/integrations.html#security",
  },
  {
    id: "cloud_integrations",
    href: "https://www.blackduck.com/integrations.html#cloud",
  },
];

export default function Products() {
  return (
    <NavDropdownMenu
      ns="home-menu"
      id="products"
      dropdownItems={dropdownItems}
    ></NavDropdownMenu>
  );
}
