import Flag from "react-flagpack";
import "react-flagpack/dist/style.css";

export interface FlagProps {
  code: string;
}

export default function CountryFlag(props: FlagProps) {
  return <Flag code={props.code} size="m" gradient="real-circular"></Flag>;
}
