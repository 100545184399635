import React from "react";
import NavDropdown from "react-bootstrap/NavDropdown";

interface NavDropdownItemExternalProps {
  href: string;
  children: string | React.ReactElement;
}

export default function NavDropdownExternal(
  props: NavDropdownItemExternalProps
) {
  return (
    <NavDropdown.Item
      target="_blank"
      rel="noopener noreferrer"
      href={props.href}
    >
      {props.children}
    </NavDropdown.Item>
  );
}
