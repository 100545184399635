import { Suspense } from "react";
import Homepage from "./modules/homepage/Homepage";

function setTheme() {
  const htmlElement = document.querySelector("html");
  htmlElement?.setAttribute("data-bs-theme", "light");
}

function App() {
  setTheme();

  return (
    <Suspense>
      <Homepage></Homepage>
    </Suspense>
  );
}

export default App;
