import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavItem, NavLink } from "react-bootstrap";

export default function LinkedIn() {
  return (
    <NavItem>
      <NavLink
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.linkedin.com/company/softegrity"
      >
        <FontAwesomeIcon icon={faLinkedin}></FontAwesomeIcon>
      </NavLink>
    </NavItem>
  );
}
