import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Footer.css";

export default function Footer() {
  return (
    <footer className="stgt-footer text-muted">
      &copy; Softegrity 2024 &nbsp;
      <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
      &nbsp;
      <a href="mailto:contacto@softegrity.cl">contacto@softegrity.cl</a>
      &nbsp;&nbsp;
      <a
        href="https://www.linkedin.com/company/softegrity"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faLinkedin}></FontAwesomeIcon>
      </a>
      <br />
      Av. Lib. Bernardo O'Higgins 1302, Of. 70, Santiago, Chile
    </footer>
  );
}
